import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

const COURSE_LIST = [
  {
    code: 'COMP1111',
    name: 'Introduction to Programming',
    department: 'COMP',
    faculty: 'ENGG',
    isCC: false,
    description: 'There is no description yet.'
  },
  {
    code: 'COMP3311',
    name: 'Legal Aspects of Computing',
    department: 'COMP',
    faculty: 'ENGG',
    isCC: false,
    description: 'There is no description yet.'
  },
  {
    code: 'CCSC1007',
    name: 'Wave and Ocean',
    department: 'EARTHSC',
    faculty: 'SCI',
    isCC: true,
    description: 'There is no description yet.'
  },
  {
    code: 'PHIL2115',
    name: 'Skepticism and Relativeism',
    department: 'PHIL',
    faculty: 'ARTS',
    isCC: false,
    description: 'There is no description yet.'
  },
  {
    code: 'POLI1002',
    name: 'Introduction to Political Science',
    department: 'POLI',
    faculty: 'SOCSCI',
    isCC: false,
    description: 'There is no description yet.'
  }
]

let REVIEW_LIST = [
  {
    courseCode: 'COMP1111',
    user: 'Anonymous',
    grade: 'A+',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    createdAt: '2019/02/01'
  },
  {
    courseCode: 'COMP1111',
    user: 'Anonymous',
    grade: 'B',
    content: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    createdAt: '2019/01/21'
  },
  {
    courseCode: 'POLI1002',
    user: 'Anonymous',
    grade: 'F',
    content: 'Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    createdAt: '2019/01/08'
  },
  {
    courseCode: 'PHIL2115',
    user: 'Anonymous',
    grade: 'B+',
    content: 'I don\'t know what is going on actually.',
    createdAt: '2019/02/06'
  },
  {
    courseCode: 'CCSC1007',
    user: 'Anonymous',
    grade: 'C+',
    content: 'Don\'t choose it. It is the worse grade subject that i\'ve ever took in HKU...Try it if you want to get an C...',
    createdAt: '2019/01/15'
  }
]

class Navbar extends Component {
  render() {
    return (
      <div className="navbar">
        <div className="navbar-brand">🙇🏻📖💪🔥👩‍🎓</div>
      </div>
    );
  }
}


// class CourseFilter extends Component {
//   render() {
//     return (
//       <div>
//         <input class="course-serach" type="text" name="course-serach" value={this.props.courseSearchText || ''} onChange={this.props.handleChange} />
//       </div>
//     );
//   }
// }


class CourseListEntry extends Component {


  handleClick = (course) => {
    this.props.onSelectedCourse(course)
  }

  render() {
    const course = this.props.course;
    let classList = ['course-list-entry'];
    if (this.props.isSelected) {
      classList.push('active');
    }
    return (
      <div className={classList.join(' ')} onClick={this.handleClick.bind(this, course)}>
        <h4>{course.code}<br />{course.name}</h4>
        <div>Faculty: {course.faculty}</div>
        <div>Department: {course.department}</div>
        <div>Common Core: {course.isCC ? 'Yes' : 'No'}</div>
      </div>
    );
  }
}


class CourseList extends Component {

  render() {
    let filter = this.props.courseSearchText
    console.log(filter)
    const listOfCourse = this.props.listOfCourse;
    const courseEntries = listOfCourse.map((course) => {
      if (!filter || course.code.toLowerCase().includes(filter.toLowerCase()) || course.name.toLowerCase().includes(filter.toLowerCase()) || course.description.toLowerCase().includes(filter.toLowerCase())) {
        return <CourseListEntry key={course.code} course={course} onSelectedCourse={this.props.onSelectedCourse} isSelected={this.props.selectedCourse === course ? true : false} />
      }
    });
    return (
      <div class="course-list">
        {courseEntries}
      </div>
    );
  }
}

class ReviewForm extends Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.handleSubmit(e);
  }

  handleChange = (e) => {
    this.props.handleChange(e);
  }

  render() {
    return (
      <div className="review-form-wrapper">
        <form onSubmit={this.handleSubmit} className="review-form">
          <div>
            <label>
              Name:</label>
            <input type="text" name="name" value={this.props.reviewFrom.name || ''} onChange={this.handleChange} />

          </div>
          <div>


            <label>
              Grade: </label>
            <select name="grade" value={this.props.reviewFrom.grade || ''} onChange={this.handleChange}>
              <option value="A+">A+</option>
              <option value="A">A</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B">B</option>
              <option value="B-">B-</option>
              <option value="C+">C+</option>
              <option value="C">C</option>
              <option value="C-">C-</option>
              <option value="D+">D+</option>
              <option value="D">D</option>
              <option value="E">E</option>
              <option value="F">F</option>
            </select>

          </div>
          <div>
            <label>
              Comment: </label>
            <textarea minlength="10" maxlength="10240" name="content" value={this.props.reviewFrom.content || ''} onChange={this.handleChange}></textarea>

          </div>
          <button class="submitBtn">Submit</button>
        </form>
      </div>
    );
  }
}

class ReviewEntry extends Component {
  render() {
    const review = this.props.review;
    return (
      <div className="review-list-entry">
        <div>Grade: {review.grade}</div>
        <div>Workload: 3</div>
        <p>{review.content}</p>
        <div>{review.user} @ {review.createdAt}</div>
      </div>
    );
  }
}

class ReviewList extends Component {
  render() {
    const listOfReview = this.props.listOfReview;
    // console.log(this.props.listOfReview);  
    if (listOfReview && listOfReview.length) {
      const reviewEntries = listOfReview.map((review, index) =>
        <ReviewEntry key={index} review={review} />
      );
      return (
        <div className="review-list">
          {reviewEntries}
        </div>
      );
    } else {
      return (
        <div className="review-list">
          <div className="is-centered padded">There is no review yet.</div>
        </div>
      );
    }

  }
}

class CourseInfo extends Component {
  render() {
    return (
      <div className="course-details">
        <h4>Course description</h4>
        <p>Teach you everything within the course description</p>
        <h4>Statistics</h4>
        <p>Our data scientist are working on it 🙇<br />Please help us to add more reviews in the meantime 💪</p>
      </div>
    )
  }
}

class Dashboard extends Component {

  render() {
    return (
      <div className="dashboard">
        <div className="first-wrapper">
          <h4>Filter</h4>
          <div>
            <input type="checkbox" id="comp" name="comp" />
            <label for="comp">Computer Science</label>
          </div>
          <div>
            <input type="checkbox" id="phil" name="phil" />
            <label for="phil">Philosophy</label>
          </div>
          <div>
            <div>
              <input type="checkbox" id="psy" name="psy" />
              <label for="psy">Psychology</label>
            </div>
          </div>
        </div>
        <div className="middle-wrapper">
          <input class="course-serach" type="text" name="course-serach" value={this.props.courseSearchText || ''} onChange={this.props.handleChange} />
          <CourseList listOfCourse={this.props.listOfCourse} courseSearchText={this.props.courseSearchText} onSelectedCourse={this.props.onSelectedCourse} selectedCourse={this.props.selectedCourse} />
        </div>
        <div className="third-wrapper">
          {this.props.selectedCourse.code ? (
            <div>
              <CourseInfo />
              <ReviewList listOfReview={this.props.listOfReview} />
              <ReviewForm reviewFrom={this.props.reviewFrom} handleChange={this.props.handleChange} handleSubmit={this.props.handleSubmit} />
            </div>) : (
              <div>
                <h4 className="is-centered">👈 choose a class first</h4>
              </div>
            )}
        </div>
      </div>
    );
  }
}

class Landing extends Component {
  render() {
    return (
      <div class="landing-page">
        <h2>Probably the best course review site in the universe</h2>
        <button onClick={this.props.landing}>Let's get started</button>
      </div>
    )
  }
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      landed: true, //DEV
      courseList: COURSE_LIST,
      courseSearchText: '',
      course: {},
      reviews: [],
      reviewFrom: {
        name: '',
        grade: 'A+',
        content: ''
      }
    }
  }

  getCurrentDateTime = () => {
    let utc = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
    return utc;
  }

  handleSelectedCourse = (c) => {

    let selectedReviews = REVIEW_LIST.filter((r) => r.courseCode === c.code)
    this.setState({ course: c, reviews: selectedReviews }, () => {
      // console.log(this.state);
    });

  }

  handleChange = (e) => {
    if (e.target.name === 'course-serach') {
      let s = e.target.value;

      this.setState({ courseSearchText: s });

    } else {
      let reviewFrom = this.state.reviewFrom
      reviewFrom[e.target.name] = e.target.value
      this.setState({ reviewFrom });
    }

  }

  handleSubmit = (e) => {
    if (this.state.course) {
      REVIEW_LIST.push(
        {
          courseCode: this.state.course.code,
          user: this.state.reviewFrom.name,
          grade: this.state.reviewFrom.grade,
          content: this.state.reviewFrom.content,
          createdAt: this.getCurrentDateTime()
        }
      )
      this.handleSelectedCourse(this.state.course)
      let reviewFrom = {
        name: '',
        grade: 'A+',
        content: ''
      }
      this.setState({ reviewFrom });
    }
  }

  handleLanding = () => {
    this.setState({ landed: true })
  }

  render() {
    return (
      <div className="wrapper">
        <Navbar />

        {!this.state.landed &&
          <Landing landing={this.handleLanding} />
        }

        {this.state.landed &&
          <div className="main-container">
            <Dashboard listOfCourse={this.state.courseList}
              listOfReview={this.state.reviews} onSelectedCourse={this.handleSelectedCourse}
              selectedCourse={this.state.course} reviewFrom={this.state.reviewFrom}
              handleChange={this.handleChange} handleSubmit={this.handleSubmit} courseSearchText={this.state.courseSearchText} />
          </div>
        }

      </div>
    );
  }
}

export default App;
